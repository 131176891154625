.infosPolice {
    padding-top:10px;
    padding-left:0px !important;  
}

@media(min-width:767px) {
    .infosPolice {
        display: flex;
    }
}

.detailCouvertures {
    padding-top:10px;
    margin-top: 20px;
}

.detailsValeursFinancieres {
    padding-top:10px;
}

.sectionNotes {
    margin-top:32px;
    margin-bottom:60px;
}

.sectionEntete {
    margin-top: 20px;
}

.titreDetailDetentions {
    margin-bottom: 0px !important;
}

.titreTypeProduit {
    margin-top: 5px !important;
}

.containerDetailsPolice {
    padding-left:15px;
    padding-right:15px;
}

/* Infos police */

.ligneInfo {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    line-height: 1.5rem !important;
    padding-left: 0px !important;
    clear:both;
}

.labelLigneInfo {
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    margin-right: auto;
}

.sCoPreneurs {
    float: left;
    margin: 0 !important;
    padding: 0 !important;
}

.sPartiesLiees {
    float: left;
    margin: 0 !important;
    padding: 0 !important;
}

.ulPartiesLiees {
    float: left;
    margin-top: 3px !important;
    margin-bottom: 0px !important;
    padding-left: 7px !important;
}

.liPartiesLiees {
    list-style-type: none;
    padding-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 5px !important;
}

.imageCarteCredit {
    
    margin-bottom: 2px !important;
}

.texteCarteCredit {
    margin-right: 10px !important;
}

.ulPanels {
    padding-left:0px !important;
}

@media(min-width:767px) {
    .ulPanelDetail {
        margin-right:20px;
    }
}

@media (min-width: 992px) {
    .sansMarginBottomEnLarge {
        margin-bottom : 0px !important;
    }
  }

.sansMarginBottom {
    margin-bottom : 0px !important;
}

/* Détail des couvertures */

.sansPadding {
    padding-left:0px !important;
}

.montantSurprimeTemp {
    min-width: 120px;
}

.texteCouverturesActives {
    margin-left: 10px;
    display : block !important;
}

.titreSection {
    display : inline !important;
}

.sectionCaseACocherAfficherSeulement {
    margin-top : 17px;
    display : block !important;
}

.texteCaseACocherAfficherSeulement {
    margin-left : 10px;
}

/* Détail valeurs financières */

.hideBackground {
    background-color: white !important; 
}

.cacher {
    display: none;
}

/* Notes */

.ulNote {
    padding-left: 0px !important;
}

.liNote {
    padding-left: 0px !important;
}

.lienExposant {
    color:#00884e !important;
    text-decoration: none !important;
}

.titreNotes {    
    color: #007944;
    font-size: 1.4rem;
    font-weight: 700;
}

/* Affichage exposant */

.appelNote {
    margin-left : 4px !important;
    color:#00884e !important;
    text-decoration: underline !important;
    font-weight: normal !important;
}

.noBorders {
    border: none !important;
}

.tableau {
    border: none !important;
    display: inline !important;
}

.noBordersEtBgColorInherit {
    background-color:inherit !important;
    border: none !important;
}

/* Commun */

.colonneDate {
    min-width: 120px;
}

.noWrap {
    white-space : nowrap !important;
}

.oddTitle {
    background-color: #f2f2f2 !important;
    text-align:left !important; 
}

.row.equal, .form-group.equal {
    display: flex;
    flex-wrap: wrap;
  }
  [class*=col].stretch {
    display: flex;
  }
   
  /*breakpoint dépend de la grid*/
  @media (max-width: 575px) {
    [class*=col].stretch, 
    [class*=col].stretch .panel-simple {
      flex: 0 0 100%;
    }
  }
  
  @media (min-width: 576px) and (max-width:991px) {
    .flexInfosGenerales50 {
        flex: 0 0 49.93%;
    }
    .flexInfosDetention50 {
        flex: 0 0 49.93%;
    }
  }
  
  @media (min-width: 992px) {
    .flexPanelsLarge33 {
        flex: 0 0 33.3%;
    }
    .flexPanelsLarge50 {
      flex: 0 0 49.93%;
  }
  }
  
  .panel-default {
    width: 100%;
  }
  
  .displayNone {
    display: none !important;
  }

  /*footer*/

  .zone-pied-de-page {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .isolation-bootstrap-3 .container {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding-bottom: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .isolation-bootstrap-3 .copyright {
    font-size: 13px;
  }

  #footerDiv{
    background-color:#2E2E2E;
  }

  .isolation-bootstrap-3.d2-0 ul > li:before {
	display: none !important;
  }

  .isolation-bootstrap-3.d2-0 ul > li {
	padding-left: 0.6rem;
	padding-right: 0.6rem;
  }
    
  .isolation-bootstrap-3 footer {
    text-align: center;
    background-color: #35383d;
    color: #DDDDDD;
    overflow: hidden;
    min-height: 2rem;
    padding-top: 2.2rem;
    padding-bottom: 1rem;
    padding-left: 5px;
    padding-right: 5px;
  }

  .isolation-bootstrap-3 footer p {
	line-height: 1.7rem;
  }

  .isolation-bootstrap-3 footer a:link,
  .isolation-bootstrap-3 footer a:visited {
	color: #DDDDDD;
	font-weight: 700;
  }

  .isolation-bootstrap-3 footer ul li {
	margin: 0px;
	padding: 0 0.4rem 0 0.7rem;
	display: inline;
	border-left: 1px solid #d3d3d3;
  }

  .isolation-bootstrap-3 footer ul li:first-child {
	border-left: none;
  }

  .isolation-bootstrap-3 footer ul li a {
	text-decoration: none;
  }

  .isolation-bootstrap-3 footer a:hover {
	color: #DDDDDD;
	text-decoration: underline;
  }

  .isolation-bootstrap-3 footer a:link {
	text-decoration: none;
	font-size: 13px;
  }

  .isolation-bootstrap-3 footer nav ul {
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 20px;
  }

  .isolation-bootstrap-3 footer nav ul li {
	background: none;
	display: inline-block;
	padding: 0 0.3rem 0 0.6rem;
	border-left: 1px solid #cbcbcb;
	font-weight: 700;
	white-space: nowrap;
	line-height: 1.7rem;
  }

  .isolation-bootstrap-3 footer nav ul li:first-child {
	margin-left: 0;
	padding-left: 0;
	border-left: 0;
  }

  .isolation-bootstrap-3 footer .copyright {
	margin-top: 2rem;
  }

  .logo-securite {
    position: relative;
    top: -39px;
    float: right;
    margin-bottom: -30px;
    margin-top: 0px;
  }

  @media only screen and (max-width : 1280px) {
	.logo-securite {
		position: inherit !important;
		top: 0px !important;
		float: inherit !important;
		margin-bottom: 0px !important;
		margin-top: 15px !important;
	}
  }

  /* header */

  .zone-entete {
    padding-bottom: 1rem;
    padding-top: 1rem;
    height: 6.4rem;
  }

  @media only screen and (max-width : 991px) {
	#logoDsf {
		height: 5.3rem;
		padding-right: 38px;
	}

	#secureSection {
		margin-top: 1.6rem;
	}
  }

  #logoDsf {
    height: 71px;
    width: 187px;
    max-width: none !important;
  }

  .zone-logo {
	float: left;
	height: 95px;
	padding-left: 0px !important;
	padding-right: 0px !important;
  }

  #urlLogo {
    display: block;
  }

  .isolation-bootstrap-3 img {
    border: 0;
    vertical-align: middle;
  }

  .containerLogo {
      margin-left: 0px !important;
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
  }

  /* preloader */ 
  
  .loaderWrapper {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }

  /* impression  */

  @media print {

   @page { size: landscape;  margin-left: 10mm; margin-right: 10mm; margin-top: 5mm; margin-bottom: 5mm }
   
   .zone-logo { page-break-inside:auto }
   .detailCouvertures { page-break-inside:avoid }
   #detailBeneficiaires { page-break-inside:avoid }
   #sectionBeneficiairesSubroges { page-break-inside:avoid }
   #detailsFondsEpargne { page-break-inside:avoid }
   .detailsValeursFinancieres { page-break-inside:avoid }
   .detailsValeursFinancieresVu { page-break-inside:avoid }
   .zone-pied-de-page { display: none }
   .sectionNotes { page-break-inside:avoid }
   body { zoom: 0.9 }

   thead { display:table-header-group }
   tfoot { display:table-footer-group }

    a[href]:after {
       display: none;
       visibility: hidden;
    }

    li{
        list-style-type: none;
    }

    .table-responsive {
        overflow-x: visible !important;
    }
 }
